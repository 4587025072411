import { render, staticRenderFns } from "./TeacherDialog.vue?vue&type=template&id=e868aabc&scoped=true&"
import script from "./TeacherDialog.vue?vue&type=script&lang=js&"
export * from "./TeacherDialog.vue?vue&type=script&lang=js&"
import style0 from "./TeacherDialog.vue?vue&type=style&index=0&id=e868aabc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e868aabc",
  null
  
)

export default component.exports